@font-face {
  font-family: "Korinna";
  src: url(./fonts/korinna_regular.otf);
}

@font-face {
  font-family: "Korinna";
  src: url(./fonts/korinna_bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Swiss 911";
  src: url(./fonts/Swiss911.ttf);
}

@font-face {
  font-family: "Swiss 921";
  src: url(./fonts/Swiss921.ttf);
}

@font-face {
  font-family: "Rock Salt";
  src: url(./fonts/RockSalt-Regular.ttf);
}

@font-face {
  font-family: "SueEllen";
  src: url(./fonts/SueEllenFrancisco.ttf);
}

@font-face {
  font-family: "Zurich";
  src: url(./fonts/Zurich_Black.ttf);
}

@font-face {
  font-family: "Press Start";
  src: url(./fonts/PressStart.ttf);
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
